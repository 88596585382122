import classNames from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import * as styles from './Team.module.scss';

const Team: React.FC = () => {
  return (
    <section className={styles.root}>
      <h1 className={styles.title}>Zespół</h1>

      <div className={styles.section}>
        <div className={styles.content}>
          <div className={styles.name}>lek. dent. Anna Stós</div>
          <div className={styles.position}>Stomatolog</div>

          <p className={styles.description}>
            Mam ponad 20 lat doświadczenia pracy jako lekarz stomatolog. Praktykę i wiedzę zawdzięczm wielu znakomitym
            specjalistom, z kraju i zagranicy. Dzięki nim moge dziś Państwu zaoferować swoją pomoc trudnych problemach
            stomatologicznych.
          </p>

          <p className={styles.description}>
            Zdrowie i zadowolenie Pacjentów jest dla mnie motywacją do dalszej pracy i ciągłego kształcenia zawodowego.
            W czasach szybkiego rozwoju medycyny ciągłe kursy i szkolenia pozwalają mi być zawsze na bieżąco w
            nowoczesnych technikach w stomatologii.
          </p>

          <p className={styles.description}>
            Znaczna część moich Pacjentów trafia do mnie z polecenia znajomych i zadowolonych klientów – pozwala to mi
            mieć pewność, że dla Nich moja praca i zaangażowanie stanowią dużą wartość.
          </p>

          <p className={styles.description}>
            W związku ciągle zawężającymi się specjalizacjami w stomatologii współpracujemy na codzień ze specjalistami
            z dziedziny endodoncji (mikroskop) i chirurgi stomatologicznej (ekstrakcje zębów ósmych, implanty,
            przeszczepy tkankowe, recesja dziąsła).
          </p>
        </div>

        <div className={styles.imageContainer}>
          <StaticImage
            src='../../assets/photos/anna.png'
            alt='Anna Stós'
            className={styles.image}
            placeholder='tracedSVG'
            objectFit='contain'
            quality={95}
          />
        </div>
      </div>

      <div className={classNames(styles.section, styles.leftImage)}>
        <div className={styles.content}>
          <div className={styles.name}>Edyta Kajmowicz</div>
          <div className={styles.position}>Dyplomowana higienistka</div>
          <p className={styles.description}>
            Od ponad 10ciu lat pracuję jako dyplomowana higienistka stomatologiczna. Z wielkim zaangażowaniem wykonuje
            zabiegi higienizacyjny u pacjentów - piaskowanie, ściąganie kamienia, lakierowanie oraz lakowanie i
            lapisowanie.
          </p>

          <p className={styles.description}>
            Zajmuje się też szeroko pojętą edukacja stomatologiczną, cierpliwie tłumaczę pacjentom, jak prawidłowo
            czyścić zęby, jakich dodatkowych urządzeń i metod używać, aby sami umieli zadbać o swój piękny uśmiech i
            świeży oddech.
          </p>
        </div>

        <div className={styles.imageContainer}>
          <StaticImage
            src='../../assets/photos/edyta.png'
            alt='Edyta Kajmowicz'
            className={styles.image}
            placeholder='tracedSVG'
            objectFit='contain'
            quality={95}
          />
        </div>
      </div>
    </section>
  );
};

export default Team;
