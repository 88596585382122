// extracted by mini-css-extract-plugin
export var root = "Team-module--root--GwyiE";
export var section = "Team-module--section--3Lx94";
export var title = "Team-module--title--1684I";
export var imageContainer = "Team-module--imageContainer--3wsNo";
export var content = "Team-module--content--1V71j";
export var image = "Team-module--image--KicE_";
export var subtitle = "Team-module--subtitle--3J_17";
export var name = "Team-module--name--Mtbp3";
export var position = "Team-module--position--1OI0g";
export var description = "Team-module--description--ujoX8";
export var leftImage = "Team-module--leftImage--I80-s";